import React, { useEffect, useState } from 'react';
import { Button, Input, Table, Typography, Row, Col, Pagination, notification, Dropdown, Menu, Spin } from 'antd';
import CreateGroupDrawer from './CreateGroupDrawer';
import { EditOutlined, ControlOutlined } from '@ant-design/icons';
import { axiosInstance } from '../DataLayer/axiosInstance';

const Groups = () => {
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editGroup, setEditGroup] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [flagFilter, setFlagFilter] = useState('0'); // Default to 'All'
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const { Title } = Typography;
  const { Search } = Input;

  useEffect(() => {
    getgroupsdata();
  }, []);

  const getgroupsdata = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/api/Group/GetAllGroups');
      setGroups(response.data);
      setFilteredGroups(response.data);
    } catch (error) {
      console.error('Error fetching group data:', error.response || error.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const openDrawer = () => {
    setEditGroup(null);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };


  const handleCreateGroup = (newGroup) => {
    if (editGroup) {
      const updatedGroups = groups?.map(g => g.id === editGroup?.id ? { ...g, ...newGroup } : g);
      setGroups(updatedGroups);
      setFilteredGroups(updatedGroups);
    } else {
      const newGroupData = {
        ...newGroup,
        id: groups?.length + 1,
        status: 'active',
      };
      setGroups([...groups, newGroupData]);
      setFilteredGroups([...groups, newGroupData]);
    }
    getgroupsdata();
    closeDrawer();
  };


  const handleEditGroup = (group) => {
    setEditGroup(group);
    setIsDrawerOpen(true);
  };

  const filterGroups = (searchValue, filterValue) => {
    const lowerSearchValue = searchValue?.toLowerCase();

    let filtered = groups?.filter(group =>
      group?.groupName.toLowerCase().includes(lowerSearchValue) ||
      (group?.description && group?.description.toLowerCase().includes(lowerSearchValue))
    );

    if (filterValue?.key === '1') {
      filtered = filtered?.filter(group => group?.isActive);
    } else if (filterValue?.key === '2') {
      filtered = filtered?.filter(group => !group?.isActive);
    }

    setFilteredGroups(filtered);
  };

  const handleFlagChange = (value) => {
    setFlagFilter(value);
    filterGroups(searchText, value);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    filterGroups(value, flagFilter);
  };

  const handleActivateGroups = async (isActive) => {
    const requestData = {
      ids: selectedRowKeys,
      isActive: isActive,
    };
    setLoading(true);
    try {
      const response = await axiosInstance.post('/api/Group/ActivateOrDeactivateGroups', requestData);
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: isActive
            ? 'Groups activated successfully!'
            : 'Groups deactivated successfully!',
        });
        const updatedGroups = groups.map(group => {
          if (selectedRowKeys.includes(group.id)) {
            return { ...group, isActive };
          }
          return group;
        });
        setGroups(updatedGroups);
        setFilteredGroups(updatedGroups);
        setSelectedRowKeys([]);
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while updating the group status. Please try again.',
      });
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const paginatedApplications = filteredGroups?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const dataSource = paginatedApplications?.map(group => ({
    ...group,
    key: group?.id,
    status: group?.isActive ? 'true' : 'false',
    groupName: group?.groupName,
    onEdit: handleEditGroup,
  }));

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1); 
  };

  const menu = (
    <Menu onClick={handleFlagChange}>
      <Menu.Item key="0">All</Menu.Item>
      <Menu.Item key="1">Active</Menu.Item>
      <Menu.Item key="2">Inactive</Menu.Item>
    </Menu>
  );
  const groupColumns = [
    {
      title: 'S.No',
      key:"index",
      render:(value, item, index) => (currentPage - 1) * 10 + index + 1,
      width: 60,
    },
    {
      title: 'Name',
      dataIndex: 'groupName',
      key: 'groupName',
      width: 200,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 200,
      render: (text) => text || 'No Description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <Button icon={<EditOutlined />} onClick={() => handleEditGroup(record)}></Button>
      ),
    },
  ];


  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  return (
    <div className="Container">
      <Spin spinning={loading}>
        <Row gutter={12} style={{ alignItems: 'center' }}>
          <Col xs={12} lg={21}>
            <Title level={4}>Groups</Title>
          </Col>
          <Col xs={12} lg={3}>
            <Button type="primary" className="AddOrUpdateButton" onClick={openDrawer}>Create Group</Button>

          </Col>
        </Row>
        <Row gutter={8} style={{ marginBottom: 10 }}> {/* Decrease gutter and margin */}
          <Col xs={2} lg={1}>
            <div className="icon-container">
              <Dropdown overlay={menu} trigger={['click']}>
                <ControlOutlined className="rotated-icon" />
              </Dropdown>
            </div>
          </Col>
          <Col xs={20} lg={16}>
            <Search
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: '50%', height: '25px' }} // Adjust height if needed
              value={searchText}
            />

          </Col>

          <Col xs={12} lg={3}>
            {
              !selectedRowKeys || selectedRowKeys.length === 0 ? (
                <Button
                  danger
                  ghost
                  className='DeactivateButton'
                  disabled={!selectedRowKeys || selectedRowKeys.length === 0}
                  onClick={() => handleActivateGroups(false)}
                >
                  Activate
                </Button>
              ) : (
                <Button
                  ghost
                  style={{
                    width: '100%',
                    fontSize: '12px',
                    height: '25px',
                    border: '1.5px solid green',
                    color: 'green',
                    padding: '0 10px',
                  }}
                  disabled={!selectedRowKeys || selectedRowKeys.length === 0}
                  onClick={() => handleActivateGroups(true)}
                >
                  Activate
                </Button>
              )
            }


          </Col>

          <Col xs={12} lg={3}>
            <Button danger ghost
              className='DeactivateButton'
              disabled={!selectedRowKeys || selectedRowKeys.length === 0}
              onClick={() => handleActivateGroups(false)}
            >
              Deactivate
            </Button>
          </Col>
        </Row>


        <div className="TableContainer">
          <Table
            rowSelection={rowSelection}
            columns={groupColumns}
            size='small'
            dataSource={dataSource}
            pagination={false}
            scroll={{ y: 250 }}
          />
        </div>

        <CreateGroupDrawer
          isOpen={isDrawerOpen}
          onClose={closeDrawer}
          onCreateGroup={handleCreateGroup}
          group={editGroup}
        />
        <Pagination align="end" current={currentPage} total={filteredGroups?.length} pageSize={pageSize} onChange={handlePageChange} onShowSizeChange={handlePageSizeChange}  pageSizeOptions={['10', '20', '50', '100', '200']}/>
      </Spin>
    </div>
  );
};

export default Groups;
