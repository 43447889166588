import { Spin } from "antd";
import { useState } from "react";
import { axiosInstance } from "./axiosInstance";

export const useLoadingData = () => {
  const [loading, setLoading] = useState(false);

  const getGroupData = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axiosInstance.get("/api/Group/GetGroup");
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching groups data:",
        error.response || error.message
      );
      return null;
    } finally {
      setLoading(false); // End loading
    }
  };

  const getApplicationsData = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axiosInstance.get(
        "/api/Application/GetApplication"
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching applications data:",
        error.response || error.message
      );
      return null;
    } finally {
      setLoading(false); // End loading
    }
  };

  const getApplicationsDataByUser = async () => {
    setLoading(true); // Start loading
    try {
      if (!sessionStorage.getItem("token")) {
        await getToken();
      }
      const response = await axiosInstance.get(
        "/api/Application/GetAllApplicationsByUser"
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching applications data:",
        error.response || error.message
      );
      return null;
    } finally {
      setLoading(false); // End loading
    }
  };

  const getApplicationData = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axiosInstance.get(
        "/api/Application/GetAllApplications"
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching applications data:",
        error.response || error.message
      );
      return null;
    } finally {
      setLoading(false); // End loading
    }
  };

  const getToken = async () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const response = await axiosInstance.get(
      `/api/Authenticate/LoginUser?token=${localStorage.getItem(
        "adal.idtoken"
      )}`,
      {
        headers,
      }
    );
    if (response?.status === 200) {
      sessionStorage.setItem("token", response?.data);
    }
  };

  return {
    loading,
    getGroupData,
    getApplicationsData,
    getApplicationsDataByUser,
    getApplicationData,
  };
};
