import React, { useEffect, useState } from "react";
import { Layout, Dropdown, Menu, Avatar, Modal, Tooltip } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import {
  logout,
  fetchUserRoles,
  renderUserData,
  getUserProfile,
  authContext,
} from "../CommonComponets/adalConfig";
import { ChatBotComponent } from "../Components/ChatBotComponent";
import Admin from "../ChatBotProfiles/Admin.png";
import Exdion from "../ChatBotProfiles/Exdion.jpeg";
import HR from "../ChatBotProfiles/HR.png";
import IT from "../ChatBotProfiles/IT.png";
import IS from "../ChatBotProfiles/IS.png";

const { Header } = Layout;

const logos = {
  Exdion,
  HR,
  IT,
  IS,
  Admin,
};

const handleMenuClick = (e) => {
  if (e.key === "logout") {
    console.log("Logging out...");
  }
};

const defaultBotState = { isOpen: false, botName: "" };

const CustomHeader = ({ photo, userRolesData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState(authContext.getCachedUser());
  const [userRoles, setUserRoles] = useState(userRolesData);
  const [chatOption, setChatOptions] = useState(defaultBotState);
  const [profilePicData, setProfilePicData] = useState(photo);

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="profile"
        icon={<UserOutlined />}
        onClick={async () => {
          // const userResponse = await fetchUserRoles();
          // setUserRoles(userResponse);
          setIsOpen(true);
        }}
      >
        User Profile
      </Menu.Item>
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined />}
        onClick={() => {
          logout();
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const getInitials = (name) => {
    const names = name.split(" ");
    return names.length > 1 ? names[0][0] + names[1][0] : names[0][0];
  };

  return (
    <div>
      <Header
        style={{
          padding: "0 16px",
          backgroundColor: "#211261",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: 'flex-end',
          width: "100%", // Ensure full width
          position: "fixed", // Fix header at the top
          top: 0,
          left: 0,
          zIndex: 1000, // Ensure header is above other content
        }}
      >
        {/* <img
          src="MyExdion.png"
          alt="Logo"
          style={{ width: "100px", height: "50px", marginLeft: "30px" }}
        /> */}
        {/* <div style={{ display: "flex", gap: "10px" }}>
          {["Exdion", "HR", "IT", "IS", "Admin"]?.map((item, index) => {
            if (item) {
              return (
                <Tooltip
                  title={item}
                  style={{ fontSize: "5px" }}
                  placement="bottom"
                >
                  <Avatar
                    style={{ cursor: "pointer" }}
                    size={50}
                    src={`${logos[item]}`}
                    alt={item}
                    onClick={() => {
                      setChatOptions({
                        isOpen: true,
                        botName: item,
                        botImage: logos[item],
                      });
                    }}
                  />
                </Tooltip>
              );
            } else {
              return null;
            }
          })}
        </div> */}
        <span
          style={{
            color: "#fff",
            fontSize: "12px",
            // , marginLeft: "-70%"
            marginRight:'2%'
          }}
        >
          Welcome {userData?.userName || ""}
        </span>
        <Dropdown overlay={menu} trigger={["click"]}>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            {photo ? (
              <Avatar
                style={{ backgroundColor: "#fff", color: "#352973" }}
                src={photo}
                size={50}
              />
            ) : (
              <Avatar
                style={{ backgroundColor: "#fff", color: "#352973" }}
                icon={<UserOutlined />}
                size={50}
              />
            )}
          </div>
        </Dropdown>
      </Header>
      <Modal
        open={isOpen}
        title={"User Info"}
        onCancel={(e) => {
          setIsOpen(false);
        }}
        maskClosable={false}
        // footer={(_, { OkBtn, CancelBtn }) => (
        //   <>
        //     <CancelBtn />
        //   </>
        // )}
        footer={null}
        style={{marginTop:'-50px'}}
        styles={{
          body: {
            height: "70vh",
            overflowY: "auto",
            scrollbarWidth: "thin",
            wordBreak: "break-all",
          },
        }}
      >
        <div>
          <p>
            <strong>Name:</strong> {userRoles?.userData?.displayName || ""}
          </p>
          <p>
            <strong>Email:</strong>{" "}
            {userRoles?.userData?.userPrincipalName || ""}
          </p>
          <p>
            <strong>Phone:</strong> {userRoles?.userData?.mobilePhone || ""}
          </p>
          <p>
            <strong>Job Title:</strong> {userRoles?.userData?.jobTitle || ""}
          </p>
          <p>
            <strong>Office Location:</strong>{" "}
            {userRoles?.userData?.officeLocation || ""}
          </p>
          <p>
            <strong>Groups:</strong>
            <ol>
              {userRoles?.userRole
                ?.filter((f) => f["odataType"]?.includes("graph.group"))
                ?.map((e, index) => (
                  <li key={index}>{e?.displayName}</li>
                ))}
            </ol>
          </p>
          <p>
            <strong>Roles:</strong>
            <ol>
              {userRoles?.userRole
                ?.filter((f) =>
                  f["odataType"]?.includes("graph.directoryRole")
                )
                ?.map((e, index) => (
                  <li key={index}>{e?.displayName}</li>
                ))}
            </ol>
          </p>
        </div>
      </Modal>
      <ChatBotComponent
        data={chatOption}
        onClose={() => {
          setChatOptions(defaultBotState);
        }}
      />
    </div>
  );
};

export default CustomHeader;
