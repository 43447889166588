import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Input, Card, Spin, Avatar, Tooltip } from "antd";
import { useLoadingData } from "../DataLayer/CommonFunction";
import { ChatBotComponent } from "../Components/ChatBotComponent";
import Admin from "../ChatBotProfiles/Admin.png";
import Exdion from "../ChatBotProfiles/Exdion.jpeg";
import HR from "../ChatBotProfiles/HR.png";
import IT from "../ChatBotProfiles/IT.png";
import IS from "../ChatBotProfiles/IS.png";

const { Title } = Typography;
const { Search } = Input;

const logos = {
  Exdion,
  HR,
  IT,
  IS,
  Admin,
};

const defaultBotState = { isOpen: false, botName: "" };

const MyApplications = () => {
  const {
    loadingg,
    getGroupData,
    getApplicationsData,
    getApplicationsDataByUser,
    getApplicationData,
  } = useLoadingData();
  const [searchText, setSearchText] = useState("");
  const [applicationData, setApplicationData] = useState([]);
  const [filteredApplications, setFilteredApplications] =
    useState(applicationData);
  const [loading, setLoading] = useState(false);
  const [chatOption, setChatOptions] = useState(defaultBotState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const listOfCalls = [1];
        const promiseResponse = await Promise.all(
          listOfCalls.map(async (e, index) => {
            if (e === 1) {
              setLoading(true);
              const re1 = await getApplicationsDataByUser();
              setApplicationData(re1);
              setFilteredApplications(re1);
              return re1;
            }
          })
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
    fetchData();
  }, []);

  const handleSearch = (value) => {
    setSearchText(value || "");
    const searchValue = (value || "").toLowerCase();
    const filtered = applicationData?.filter(
      (app) =>
        app?.applicationName.toLowerCase().includes(searchValue) ||
        app?.applicationUrl.toLowerCase().includes(searchValue)
    );
    setFilteredApplications(filtered);
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="background-image"></div>
      <div className="Container">
        <Spin spinning={loading}>
          <Row gutter={16} style={{ marginBottom: 0 }}>
            {/* Left Section - 80% */}
            <Col xs={24} lg={19}>
              <Row
                gutter={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 15,
                }}
              >
                <Col xs={24}>
                  <Title level={3} style={{ marginBottom: 0 }}>
                    Dive into your Applications
                  </Title>
                </Col>
              </Row>
  
              <Row gutter={12} style={{ marginBottom: 10 }}>
                <Col xs={24}>
                  <Search
                    placeholder="Search applications"
                    style={{ width: "95%" }}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </Col>
              </Row>
  
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  gap: "20px",
                }}
              >
                {filteredApplications?.map((app) => (
                  <Card
                    hoverable
                    className="card-container"
                    onClick={() => window.open(app?.applicationUrl, "_blank")}
                    cover={
                      <img
                        alt={app?.applicationName}
                        src={app?.applicationImageString}
                        style={{
                          objectFit: "contain",
                          margin: "7px 0px -13px 0px",
                          padding: "7px",
                        }}
                      />
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <p
                      className="fs-12"
                      style={{
                        overflowWrap: "break-word",
                        whiteSpace: "normal",
                        margin: "0",
                        fontWeight: "bolder",
                      }}
                    >
                      {app?.applicationName}
                    </p>
                  </Card>
                ))}
              </div>
            </Col>
  
            {/* Right Section - 20% */}
            <Col xs={24} lg={5}>
            <Col xs={24}>
                  <Title level={3} style={{ marginBottom: 10,textAlign: 'center' }}>
                    Exdion Genie's
                  </Title>
                </Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {["Exdion", "HR", "IT", "IS", "Admin"]?.map((item, index) => {
                  if (item) {
                    return (
                      <Tooltip
                        title={"Ask " + (item === "Exdion" ? "About Exdion" : item)}
                        key={index}
                        placement="right"
                      >
                        <Avatar
                          size={80}
                          style={{
                            backgroundColor: "#f0f0f0",
                            cursor: "pointer",
                            border: "2px solid #ccc",
                          }}
                          src={logos[item]}
                          alt={item}
                          onClick={() => {
                            setChatOptions({
                              isOpen: true,
                              botName: item,
                              botImage: logos[item],
                            });
                          }}
                        />
                      </Tooltip>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </Col>
          </Row>
        </Spin>
  
        <ChatBotComponent
          data={chatOption}
          onClose={() => {
            setChatOptions(defaultBotState);
          }}
        />
      </div>
    </div>
  );
  
};

export default MyApplications;
