import React, { useState, useEffect } from "react";
import { axiosInstance } from "../DataLayer/axiosInstance";
import { authContext, fetchUserRoles } from "./adalConfig";
import App from "../App";
import { baseUrl } from "../DataLayer/Constants";

export const Wrapper = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setUserRoles] = useState();

  useEffect(() => {
    authContext.handleWindowCallback();

    // Check if the app is being called back
    if (!authContext.isCallback(window.location.hash)) {
      // If not authenticated, login
      if (!authContext.getCachedUser()) {
        authContext.login();
      } else {
        GenerateToken();
      }
    } else {
      // if (!sessionStorage.getItem("token")) {
      //   GenerateToken();
      // } else {
      //   updateLoading();
      // }
      updateLoading();
    }
  }, []);

  const GenerateToken = async () => {
    try {
      // if (!sessionStorage.getItem("token")) {
      const headers = {
        "Content-Type": "application/json",
      };

      const response = await axiosInstance.get(
        `/api/Authenticate/LoginUser?token=${localStorage.getItem(
          "adal.idtoken"
        )}`,
        {
          headers,
        }
      );
      if (response?.status === 200) {
        sessionStorage.setItem("token", response?.data);
      }
      const userResponse = await fetchUserRoles();
      setUserRoles(userResponse);
      updateLoading();
      // }
      // setIsLoading(false);
    } catch (error) {
      if (
        error?.response?.data?.includes("Deactivated or Invalid user login") ||
        error?.response?.data?.includes("Invalid user details") ||
        error?.response?.data?.includes("Email is missing in claims")
      ) {
        authContext.logOut();
      }
      setIsLoading(false);
    }
  };

  const updateLoading = async () => {
    const userResponse = await fetchUserRoles();
    setUserRoles(userResponse);
    setIsLoading(false);
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or any loading spinner/component
  }

  return <App userRoles={userRoles} />;
};
