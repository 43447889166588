import axios from 'axios';
import { baseUrl } from "./Constants";
import { notification } from 'antd';

export const axiosInstance = axios.create({
    baseURL: baseUrl,
});

axiosInstance.interceptors.request.use(
  (config) => {
    document.body.classList.add('loading-indicator'); 
    const token = sessionStorage.getItem('token');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    document.body.classList.remove('loading-indicator');
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    document.body.classList.remove('loading-indicator'); 
    return response;
  },
  (error) => {
    document.body.classList.remove('loading-indicator'); 
    notification.error({
      message: 'Error',
      description: 'Something went wrong. Please try again.',
    });
    return Promise.reject(error);
  }
);
