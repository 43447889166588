import React, { useEffect, useState, useRef } from "react";
import { Modal, Input, Button, Avatar } from "antd";
import {
  SendOutlined,
  UserOutlined,
  UndoOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  AboutExdionChatBotResponse,
  OtherExdionChatBotResponse,
} from "../Services/ChatBotApiServices";
import "../ChatBotStyles.css";
import { authContext } from "../CommonComponets/adalConfig";

// sample chat hostory modal / data set
// {
//   id:0, (length +1)
//   text:"",
//   type: "bot" || "user",
//   status:"inprogress" || "completed" || "failed" || undefined || null
// }

export const ChatBotComponent = (props) => {
  const chatContainerRef = useRef(null);
  const [userMessage, setUserMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([
    {
      id: 1,
      type: "bot",
      text: "Hi there...! How can I help you?",
      status: "completed",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);
  const [userData, setUserData] = useState(authContext.getCachedUser());

  useEffect(() => {
    if (props?.data?.isOpen) {
      // setChatHistory([]);
      // setTimeout(() => {
      //   setChatHistory([
      //     { type: "bot", text: "Hi there...! How can I help you?",status:"completed" },
      //   ]);
      // }, 500);
    }
    return () => {
      setUserMessage("");
      setChatHistory([
        {
          id: 1,
          type: "bot",
          text: "Hi there...! How can I help you?",
          status: "completed",
        },
      ]);
    };
  }, [props?.data?.isOpen]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [userMessage, chatHistory]);

  // const handleSendMessage = () => {
  //   if (userMessage.trim()) {
  //     setChatHistory((prevHistory) => [
  //       ...prevHistory,
  //       { type: "user", text: userMessage },
  //     ]);
  //     setUserMessage("");

  //     setIsTyping(true);

  //     setTimeout(() => {
  //       setIsTyping(false);
  //       setChatHistory((prevHistory) => [
  //         ...prevHistory,
  //         { type: "bot", text: "Connect the admin: Exdion.n@solutions.co" },
  //       ]);
  //     }, 2000);
  //   }
  // };

  const handleSendMessage = () => {
    const message = userMessage.trim();
    if (message) {
      const data = chatHistory;
      const length = chatHistory?.length;
      data.push(
        {
          id: length + 1,
          type: "user",
          text: message,
          status: "completed",
        },
        {
          id: length + 2,
          type: "bot",
          text: "",
          status: "pending",
        }
      );
      setChatHistory(data);
      setUserMessage("");
      setTimeout(() => {
        getResponse(message, length + 2);
      }, 100);
    }
  };

  const getResponse = async (message, id) => {
    const data = {
      session_id: userData?.userName || "Sam",
      category: "investors",
      query: message,
    };
    let response = "";
    if (props?.data?.botName === "Exdion") {
      response = await AboutExdionChatBotResponse(data);
    } else {
      data["category"] = props?.data?.botName?.toLowerCase();
      response = await OtherExdionChatBotResponse(data);
    }
    setChatHistory((prevChatHistory) =>
      prevChatHistory.map((chat) =>
        chat.id === id ? { ...chat, text: response, status: "completed" } : chat
      )
    );
  };

  const handleCancel = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleRefresh = () => {
    setTimeout(() => {
      setChatHistory([
        { type: "bot", text: "Hi there...! How can I help you?" },
      ]);
    }, 1000);
  };

  document.onkeyup = function (e) {
    if (chatHistory?.filter((f) => f?.status === "pending")?.length > 0) {
      return;
    }
    if (e.which === 13) {
      handleSendMessage();
    }
  };

  return (
    <div>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>{`${props?.data?.botName} ChatBot`}</span>

            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Button
                type="text"
                icon={<UndoOutlined style={{ transform: "rotate(120deg)" }} />}
                onClick={handleRefresh}
                title="Refresh Chat"
              />
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={handleCancel}
                title="Close Chat"
              />
            </div>
          </div>
        }
        maskClosable={false}
        open={props?.data?.isOpen}
        onCancel={handleCancel}
        footer={null}
        centered
        width="90%"
        closable={false}
        style={{ height: "90vh" }}
        // bodyStyle={{ padding: "0px", overflow: "hidden" }}
      >
        <hr />
        <div
          ref={chatContainerRef}
          style={{
            maxHeight: "60vh",
            overflowY: "auto",
            minHeight: "60vh",
            width: "100%",
          }}
        >
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent:
                  chat.type === "user" ? "flex-end" : "flex-start",
                marginBottom: "10px",
              }}
            >
              {/* Bot's message with avatar left side*/}
              {chat.type === "bot" && (
                <>
                  {chat?.status === "pending" ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        src={props?.data?.botImage}
                        alt={`${props?.data?.botName} bot`}
                        style={{
                          marginRight: "10px",
                          border: "#rgba(210, 204, 204, 0.76)",
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                      />
                      <div className="chat-loader">
                        <div className="dot-typing"></div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Avatar
                        src={props?.data?.botImage}
                        alt={`${props?.data?.botName} bot`}
                        style={{
                          marginRight: "10px",
                          border: "#rgba(210, 204, 204, 0.76)",
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                      />
                      <div
                        style={{
                          backgroundColor: "#e6f7ff",
                          padding: "10px",
                          borderRadius: "10px",
                          maxWidth: "75%",
                          fontSize: "11px",
                          wordWrap: "break-word",
                        }}
                      >
                        {chat.text}
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* User's message with avatar right side*/}
              {chat.type === "user" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#d9d9d9",
                      padding: "10px",
                      borderRadius: "10px",
                      maxWidth: "75%",
                      fontSize: "11px",
                      wordWrap: "break-word",
                    }}
                  >
                    {chat.text}
                  </div>
                  <Avatar
                    icon={<UserOutlined />}
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              )}
            </div>
          ))}

          {/* Typing dot animation for bot */}
          {/* {isTyping && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                src={props?.data?.botImage}
                alt={`${props?.data?.botName} bot`}
                style={{
                  marginRight: "10px",
                  border: "#rgba(210, 204, 204, 0.76)",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
              />
              <div className="chat-loader">
                <div className="dot-typing"></div>
              </div>
            </div>
          )} */}
        </div>

        <div
          style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
        >
          <Input
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            placeholder="Ask something..."
            style={{ marginRight: "10px" }}
            disabled={
              chatHistory?.filter((f) => f?.status === "pending")?.length > 0
            }
          />
          <Button
            type="primary"
            onClick={handleSendMessage}
            disabled={
              chatHistory?.filter((f) => f?.status === "pending")?.length > 0
            }
          >
            <SendOutlined className="rotated-icon-send" />
          </Button>
        </div>
      </Modal>
    </div>
  );
};
