import React, { useState } from "react";
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  CloudSyncOutlined,
} from "@ant-design/icons";
import { Menu, Layout } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { adminRole } from "../DataLayer/Constants";

const { Sider } = Layout;

const items = [
  { key: "1", icon: UserOutlined, label: "Groups", path: "/static/Groups" },
  {
    key: "2",
    icon: VideoCameraOutlined,
    label: "Applications",
    path: "/static/Applications",
  },
  {
    key: "3",
    icon: UploadOutlined,
    label: "Configurations",
    path: "/static/Configurations",
  },
  {
    key: "4",
    icon: UserOutlined,
    label: "MyApplications",
    path: "/static/myApplications",
  },
  { key: "5", icon: CloudSyncOutlined, label: "DataSync", path: "/static/dataSync" },
].map((item) => ({
  key: item.key,
  icon: React.createElement(item.icon),
  label: item.label,
  path: item.path,
}));

const userItems = [
  {
    key: "4",
    icon: UserOutlined,
    label: "MyApplications",
    path: "/myApplications",
  },
].map((item) => ({
  key: item.key,
  icon: React.createElement(item.icon),
  label: item.label,
  path: item.path,
}));

const Sidebar = ({ onCollapse, userRolesData }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleCollapse = (collapsed) => {
    setCollapsed(collapsed);
    onCollapse(collapsed);
  };

  const handleMenuClick = (e) => {
    const item = items.find((item) => item.key === e.key);
    if (item) {
      navigate(item.path);
    }
  };

  const selectedKey =
    items.find((item) => item.path === location.pathname)?.key || "1";

  return (
    <Sider
      breakpoint="lg"
      collapsed={collapsed}
      collapsible
      onCollapse={handleCollapse}
      style={{
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 64,
        bottom: 0,
        backgroundColor: "#211261",
        overflow: "auto",
        width: collapsed ? 80 : 200,
      }}
    >
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedKey]} // Set the selected key
        items={
          userRolesData?.userRole?.filter(
            (f) =>
              f["odataType"]?.includes("graph.directoryRole") &&
              f?.displayName === adminRole
          )?.length > 0
            ? items
            : userItems
        }
        onClick={handleMenuClick}
        style={{ backgroundColor: "#211261" }}
      />
    </Sider>
  );
};

export default Sidebar;
