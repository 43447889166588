import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Row, Typography, Col, Pagination, Dropdown, Menu, notification, Spin } from 'antd';
import CreateApplicationDrawer from './CreateApplicationDrawer';
import { EditOutlined, ControlOutlined } from '@ant-design/icons';
import { axiosInstance } from '../DataLayer/axiosInstance';
import { useLoadingData } from '../DataLayer/CommonFunction';


const { Search } = Input;
const { Title } = Typography;

const Applications = () => {
  const { loadingg, getGroupData, getApplicationsData, getApplicationData } = useLoadingData();
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [editApplication, setEditApplication] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [flagFilter, setFlagFilter] = useState('0'); // Default to 'All'
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadApplications();
  }, []);

  const loadApplications = async () => {
    try {
      const listOfCalls = [1];
      const promiseResponse = await Promise.all(
        listOfCalls.map(async (e, index) => {
          setLoading(true);
          if (e === 1) {
            const apllicationGetData = await getApplicationData();
            setApplications(apllicationGetData);
            setFilteredApplications(apllicationGetData);
            return apllicationGetData;
          }
        })
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };


  // const openDrawer = () => {
  //   setEditApplication(null);
  //   setIsDrawerOpen(true);
  // };

  // const closeDrawer = () => setIsDrawerOpen(false);

  // const handleCreateApplication = async (newApp) => {
  //   setLoading(true);
  //   if (editApplication) {
  //     // Editing existing application, make the API call to update
  //     const response = await axiosInstance.post('/api/Application/AddOrUpdateApplication', {
  //       id: editApplication.id,
  //       ...newApp,
  //     })
  //       .then((response) => {
  //         const updatedApps = applications.map(app =>
  //           app.id === editApplication?.id ? { ...app, ...newApp } : app
  //         );
  //         setApplications(updatedApps);
  //         setFilteredApplications(updatedApps);
  //         notification.success({
  //           message: 'Update Successful',
  //           description: 'Application has been updated successfully.',
  //         });
  //         closeDrawer();
  //       })
  //       .catch((error) => {
  //         console.error('Error updating application:', error.response || error.message);
  //         notification.error({
  //           message: 'Update Failed',
  //           description: 'There was an error updating the application. Please try again.',
  //         });
  //       });
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 500);
  //   } else {
  //     // Creating a new application
  //     setLoading(true);
  //     const response = await axiosInstance.post('/api/Application/AddOrUpdateApplication', {
  //       ...newApp,
  //     })
  //       .then((response) => {
  //         const newAppData = { ...newApp, id: response.data.id };
  //         setApplications([...applications, newAppData]);
  //         setFilteredApplications([...applications, newAppData]);
  //         notification.success({
  //           message: 'Creation Successful',
  //           description: 'Application has been created successfully.',
  //         });
  //         closeDrawer();
  //       })
  //       .catch((error) => {
  //         console.error('Error creating application:', error.response || error.message);
  //         notification.error({
  //           message: 'Creation Failed',
  //           description: 'There was an error creating the application. Please try again.',
  //         });
  //       });
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 500);
  //   }
  // };

  const handleApplicationUpdate = async () => {
    await loadApplications();
  }

  const handleFlagChange = (value) => {
    setFlagFilter(value);
    filterApplications(searchText, value);
  };

  const handleActivateApplications = async (isActive) => {
    const requestData = {
      ids: selectedRowKeys,
      isActive: isActive,
    };
    setLoading(true);
    try {
      const response = await axiosInstance.post('/api/Application/ActivateOrDeactivateApplications', requestData)
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: isActive
            ? 'Application activated successfully!'
            : 'Application deactivated successfully!',
        });
        await loadApplications();
        setSelectedRowKeys([]); // Clear selected rows
        getApplicationData(); // Refresh the application data
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while updating the Application status. Please try again.',
      });
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const filterApplications = (searchValue, filterValue) => {
    const lowerSearchValue = searchValue?.toLowerCase();

    let filtered = applications?.filter(app =>
      app?.applicationName?.toLowerCase().includes(lowerSearchValue) ||
      app?.applicationUrl?.toLowerCase().includes(lowerSearchValue)
    );

    if (filterValue?.key === '1') {
      filtered = filtered.filter(group => group?.isActive);
    } else if (filterValue?.key === '2') {
      filtered = filtered.filter(group => !group?.isActive);
    }

    setFilteredApplications(filtered);
    setCurrentPage(1);
  };

  const handleEditApplication = (app) => {
    setSelectedApplication(app);
    setIsDrawerOpen(true);
  };

  const handleCreateNew = () => {
    setSelectedApplication(null);
    setIsDrawerOpen(true);
  }

  const handleDrawerClose = () => {
    if (selectedApplication) {
      setSelectedApplication(null);  
    }
    setIsDrawerOpen(false);
  };
  

  const handleSearch = (value) => {
    setSearchText(value || '');
    filterApplications(value, flagFilter);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1); 
  };

  const menu = (
    <Menu onClick={handleFlagChange}>
      <Menu.Item key="0">All</Menu.Item>
      <Menu.Item key="1">Active</Menu.Item>
      <Menu.Item key="2">Inactive</Menu.Item>
    </Menu>
  );

  const paginatedApplications = filteredApplications?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const columns = [
    {
      title: 'S.No',
      key:"index",
      render:(value, item, index) => (currentPage - 1) * 10 + index + 1,
      width: 60,
    },
    {
      title: 'Name',
      dataIndex: 'applicationName',
      key: 'applicationName',
    },
    {
      title: 'Icon',
      dataIndex: 'applicationImageString',
      key: 'applicationImageString',
      render: (icon) => <img src={icon} alt="noImage" style={{ maxWidth: '70px', height: '35px' }} />,
    },
    {
      title: 'URL',
      dataIndex: 'applicationUrl',
      key: 'applicationUrl',
      render: (url) => <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => (isActive ? 'true' : 'false')
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <Button icon={<EditOutlined />} onClick={() => handleEditApplication(record)}>
        </Button>
      ),
    },
  ];

  // Row selection setup
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
    // If you want to disable row selection based on condition
    // getCheckboxProps: (record) => ({
    //   disabled: record.status === 'inactive', // Disable checkbox if group is inactive
    // }),
  };

  return (
    <div className="Container">
      <Spin spinning={loading}>
        <Row gutter={12} style={{ alignItems: 'center' }}>
          <Col xs={12} lg={21}>
            <Title level={4}>Applications</Title>
          </Col>
          <Col xs={12} lg={3}>
            <Button type="primary" className="AddOrUpdateButton" onClick={handleCreateNew}>New Application</Button>
          </Col>
        </Row>
        <Row gutter={12} style={{ marginBottom: 10 }}>
          <Col xs={2} lg={1}>
            <div className="icon-container">
              <Dropdown overlay={menu} trigger={['click']}>
                <ControlOutlined className="rotated-icon" />
              </Dropdown>
            </div>
          </Col>
          <Col xs={20} lg={16}>
            <Search
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: '50%' }}
              value={searchText}
            />
          </Col>
          <Col xs={12} lg={3}>
            {
              !selectedRowKeys || selectedRowKeys?.length === 0 ? (
                <Button
                  danger
                  ghost
                  className='DeactivateButton'
                  disabled={!selectedRowKeys || selectedRowKeys?.length === 0}
                  onClick={() => handleActivateApplications(false)}
                >
                  Activate
                </Button>
              ) : (
                <Button
                  ghost
                  style={{
                    width: '100%',
                    fontSize: '12px',
                    height: '25px',
                    border: '1.5px solid green',
                    color: 'green',
                    padding: '0 10px',
                  }}
                  disabled={!selectedRowKeys || selectedRowKeys?.length === 0}
                  onClick={() => handleActivateApplications(true)}
                >
                  Activate
                </Button>
              )
            }
          </Col>
          <Col xs={12} lg={3}>
            <Button danger ghost
              className='DeactivateButton'
              disabled={!selectedRowKeys || selectedRowKeys?.length === 0}
              onClick={() => handleActivateApplications(false)}
            >
              Deactivate
            </Button>
          </Col>
        </Row>

        <div className="TableContainer">
          <Table
            rowSelection={rowSelection}
            dataSource={paginatedApplications} 
            columns={columns}
            rowKey="id"
            size='small'
            pagination={false}
            scroll={{ y: 250 }}
          />
        </div>
        <CreateApplicationDrawer
          visible={isDrawerOpen}
          onClose={handleDrawerClose}
          // onCreateApplication={handleCreateApplication}
          application={selectedApplication}
          onApplicationUpdate={handleApplicationUpdate}
        />
       <Pagination align="end" current={currentPage} total={filteredApplications?.length} pageSize={pageSize} onChange={handlePageChange} onShowSizeChange={handlePageSizeChange}  pageSizeOptions={['10', '20', '50', '100', '200']}/>
      </Spin>
    </div>
  );
};

export default Applications;
