import React, { useState, useEffect } from 'react';
import { Form, Drawer, Button, Input, notification, Spin } from 'antd';
import { axiosInstance } from '../DataLayer/axiosInstance';

const { TextArea } = Input;

const CreateGroupDrawer = ({ isOpen, onClose, onCreateGroup, group }) => {
  const [groupName, setGroupName] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [loading, setLoading] = useState(false);

  const isEditing = !!group;

  useEffect(() => {
    if (isEditing) {
      setGroupName(group.groupName);
      setDescription(group.description);
      setIsActive(group.isActive);
    } else {
      setGroupName('');
      setDescription('');
      setIsActive(true);
    }
  }, [group, isEditing]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('/api/Group/AddOrUpdateGroup', {
        id: group?.id || 0,
        groupName: groupName || '',
        isActive: isEditing ? isActive : true,
        description
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: isEditing ? 'Group updated successfully!' : 'Group created successfully!',
        });

        // Clear the form fields after success
        setGroupName('');
        setDescription('');
        setIsActive(true);

        onCreateGroup();
        onClose();
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while saving the group. Please try again.',
      });
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };


  return (
    <Spin spinning={loading}>
      <Drawer
        title={isEditing ? 'Edit Group' : 'Create Group'}
        width={400}
        open={isOpen}
        maskClosable={false}
        closable={false}
      >
        <Form layout="vertical">
          <Form.Item
            label="Group Name"
            style={{ marginBottom: 16 }}
          >
            <Input
              placeholder="Group Name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              disabled={isEditing}
              style={{ marginBottom: 20 }}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            style={{ marginBottom: 16 }}
          >
            <TextArea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={4}
              style={{ marginBottom: 20, resize: 'none' }}
            />
          </Form.Item>
        </Form>
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onClose} style={{ marginRight: 8 }}>Cancel</Button>
          <Button onClick={handleSubmit} type="primary">
            {isEditing ? 'Update' : 'Create'}
          </Button>
        </div>
      </Drawer>
    </Spin>
  );
};

export default CreateGroupDrawer;
