import { AdalConfig, AuthenticationContext, runWithAdal } from "react-adal";
import axios from "axios";
import { baseUrl } from "../DataLayer/Constants";
import { axiosInstance } from "../DataLayer/axiosInstance";
import { useState } from "react";

const DO_NOT_LOGIN = false;

const adalConfig = {
  tenant: "ca7621e7-a15f-4dc1-9515-5cbd8ac365b8",
  clientId: "33350e5b-87cb-458e-8216-720d2664ac08",
  endpoints: {
    api: "33350e5b-87cb-458e-8216-720d2664ac08",
  },
  redirectUri: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  cacheLocation: "localStorage" || "sessionStorage",
};

const authContext = new AuthenticationContext(adalConfig);

export const isAuthenticated = () => {
  // return !!sessionStorage.getItem("token");
  const authResponse = !authContext.getCachedUser();
  return authResponse;
};

export const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
  // const appConfiguration: any = await this.apiClient.getAppConfig();
  // const loginSite = appConfiguration?.result?.find((e: any) => e.name == "IdentityProvider")?.value;
  const loginSite = "azuread";
  const baseUrl = window.location.origin.includes("localhost:")
    ? "http://localhost:3000"
    : window.location.origin;
  window.location.href =
    loginSite.toLowerCase() == "azuread"
      ? "https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=" +
        baseUrl
      : "/login";
};

const decodeToken = (token) => {
  const payload = token.split(".")[1];
  return JSON.parse(atob(payload));
};

// Function to fetch user roles
// export const fetchUserRoles = async () => {
//   return new Promise((resolve, reject) => {
//     authContext.acquireToken(
//       "https://graph.microsoft.com",
//       async (error, token) => {
//         if (error || !token) {
//           console.error("Token acquisition error:", error);
//           return reject(error);
//         }

//         try {
//           const response = await axios.get(
//             "https://graph.microsoft.com/v1.0/me/memberOf",
//             {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//             }
//           );
//           const responseData = await axios.get(
//             "https://graph.microsoft.com/v1.0/me",
//             {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//             }
//           );
//           resolve({
//             userRole: response.data.value,
//             userData: responseData.data,
//           });
//         } catch (apiError) {
//           console.error("API call error:", apiError);
//           reject(apiError);
//         }
//       }
//     );
//   });
// };

export const fetchUserRoles = async () => {
  var userData = await authContext.getCachedUser();
  const response = await axiosInstance.get(
    baseUrl + `/api/User/GetADUserDetails?userId=${userData?.profile?.oid}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return response.status === 200 ? response.data : "failed";
};

export const getUserProfile = async (id) => {
  try {
    const response1 = authContext.getCachedUser();
    authContext.acquireToken(
      "https://graph.microsoft.com",
      async (error, token) => {
        const response = await fetch(
          `https://graph.microsoft.com/v1.0/me/contacts/${response1?.profile?.oid}/photo/$value`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "image/jpeg", // or 'image/png' depending on the format
            },
          }
        );

        if (response.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
        } else {
          console.error("Error fetching user photo", response);
        }
      }
    );
  } catch (error) {}
};

export const renderUserData = async () => {
  const fetchUserInfo = async (token) => {
    try {
      const response = await axios.get("https://graph.microsoft.com/v1.0/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      //   setUserInfo(response.data);
      return response.data.value;
    } catch (error) {
      console.error("Error fetching user info:", error);
      return [];
    }
  };

  const fetchUsers = async (token) => {
    try {
      const response = await axios.get(
        "https://graph.microsoft.com/v1.0/users",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //   setUsers(response.data.value);
      console.log(response);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchGroups = async (token) => {
    try {
      const response = await axios.get(
        "https://graph.microsoft.com/v1.0/groups",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //   setGroups(response.data.value);
      console.log(response);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };
  const token =
    localStorage.getItem("adal.idtoken") ||
    sessionStorage.getItem("adal.idtoken");

  if (token) {
    const decoded = decodeToken(token);
    const now = Math.floor(Date.now() / 1000);
    if (decoded.exp < now) {
      console.error("Token has expired.");
      // Handle token expiration (e.g., re-login)
    }
  }
  authContext.acquireToken(
    "https://graph.microsoft.com",
    async (error, Tokens) => {
      if (error || !Tokens) {
        console.error("Token acquisition error:", error);
        return;
      } else {
        await fetchUserInfo(Tokens);
      }
      // Use the token for Graph API calls
    }
  );
};

export { authContext };
