import React, { useState, useEffect } from "react";
import {
  EyeOutlined,
  UsergroupAddOutlined,
  CloudSyncOutlined,
  UserOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Card } from "antd";
import { authContext } from "../CommonComponets/adalConfig";
import { baseUrl } from "../DataLayer/Constants";
import { Button, Drawer, Space, Table, Progress, Modal, Spin } from "antd";
import { axiosInstance } from "../DataLayer/axiosInstance";
import GroupUsers from "./GroupUsers";

const DataSync = () => {
  const [userData, setUserData] = useState({});
  const [groupData, setGroupData] = useState({});
  const [drawerStatus, SetDrawerStatus] = useState({
    isOpen: false,
    title: "",
  });
  const [modalStatus, SetModalStatus] = useState({
    isOpen: false,
    title: "",
  });

  const [loading, setLoading] = useState(false);

  const [pendingUsers, SetPendingUsers] = useState([]);
  const [pendingGroups, SetPendingGroups] = useState([]);

  const [userProgressPercent, SetUserProgressPercent] = useState(0);
  const [groupProgressStatus, SetGroupProgressStatus] = useState(0);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
  });

  const userColumn = [
    {
      title: "S.No",
      dataIndex: "index",
      render: (text, record) => <span className="fs-10">{text}</span>,
      width: 70,
    },
    {
      title: "Name",
      dataIndex: "displayName",
      render: (text, record) => <span className="fs-10">{text}</span>,
    },
    {
      title: "Mail",
      dataIndex: "mail",
      render: (text, record) => <span className="fs-10">{text}</span>,
    },
  ];

  const groupColumn = [
    {
      title: "S.No",
      dataIndex: "index",
      render: (text, record) => <span className="fs-10">{text}</span>,
      width: 70,
    },
    {
      title: "Group Name",
      dataIndex: "displayName",
      render: (text, record) => <span className="fs-10">{text}</span>,
    },
  ];

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = async () => {
    setLoading(true);
    try {
      const [userDatas, groupDatas] = await Promise.all([
        getApplicationUserCount(),
        getApplicationGroupCount(),
      ]);
      if (typeof userDatas === "object") {
        setUserData(userDatas);
        const pendingUsersCount = userDatas?.usersToAdd?.length;
        if (pendingUsersCount > 0) {
          SetUserProgressPercent(
            Math.floor(
              ((userDatas?.adUsersCount - pendingUsersCount) * 100) /
                userDatas?.adUsersCount
            )
          );
          SetPendingUsers(
            userDatas?.usersToAdd?.map((e, index) => {
              return { index: index + 1, ...e };
            })
          );
        } else {
          SetPendingUsers([]);
          SetUserProgressPercent(100);
        }
      } else {
        setUserData({});
        SetPendingUsers([]);
        SetUserProgressPercent(0);
      }
      if (typeof groupDatas === "object") {
        setGroupData(groupDatas);
        const pendingGroupsCount = groupDatas?.groupsToAdd?.length;
        if (pendingGroupsCount > 0) {
          SetGroupProgressStatus(
            Math.floor(
              ((groupDatas?.adGroupsCount - pendingGroupsCount) * 100) /
                groupDatas?.adGroupsCount
            )
          );
          SetPendingGroups(
            groupDatas?.groupsToAdd?.map((e, index) => {
              return { index: index + 1, ...e };
            })
          );
        } else {
          SetPendingGroups([]);
          SetGroupProgressStatus(100);
        }
      } else {
        SetPendingGroups([]);
        setGroupData({});
        SetGroupProgressStatus(0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getApplicationUserCount = async () => {
    try {
      const response = await axiosInstance.get(
        baseUrl + "/api/User/GetADUsersDetails",
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return response.status === 200 ? response.data : "failed";
    } catch {
      return "failed";
    }
  };

  const getApplicationGroupCount = async () => {
    try {
      const response = await axiosInstance.get(
        baseUrl + "/api/User/GetADGroupsDetails",
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return response.status === 200 ? response.data : "failed";
    } catch {
      return "failed";
    }
  };

  const syncData = async (title) => {
    setLoading(true);
    try {
      const headers = {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      if (title === "user") {
        const data = pendingUsers.map((e) => {
          return {
            id: 0,
            userName: e?.displayName,
            userEmail: e?.mail ? e?.mail : e?.userPrincipalName,
            isActive: true,
            createdBy: 0,
            updatedBy: 0,
            createdOn: new Date(),
            updatedOn: new Date(),
          };
        });
        await axiosInstance.post(baseUrl + "/api/User/AddUsers", data, {
          headers,
        });
      } else if (title === "group") {
        const groupDataToAdd = pendingGroups.map((e) => {
          return {
            id: 0,
            groupName: e?.displayName,
            description: e?.description,
            isActive: true,
            createdBy: 0,
            updatedBy: 0,
            createdOn: new Date(),
            updatedOn: new Date(),
          };
        });
        await axiosInstance.post(
          baseUrl + "/api/Group/SyncGroups",
          groupDataToAdd,
          {
            headers,
          }
        );
      }
      await refreshData();
    } catch (error) {
      await refreshData();
    }
  };

  const drawerState = (isOpen, title) => {
    SetDrawerStatus({ isOpen, title });
    if (!isOpen) {
      setPagination({ current: 1, pageSize: 5 });
    }
  };

  const modalState = (isOpen, title) => {
    SetModalStatus({ isOpen, title });
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination.current,
    });
  };

  const userActions = [
    <EyeOutlined
      key="viewUser"
      title="view pending users"
      onClick={() => {
        drawerState(true, "user");
      }}
    />,
    <CloudSyncOutlined
      key="syncUser"
      title="sync user"
      onClick={() => {
        modalState(true, "user");
      }}
    />,
  ];

  const groupActions = [
    <EyeOutlined
      key="viewGroup"
      title="view pending groups"
      onClick={() => {
        drawerState(true, "group");
      }}
    />,
    <CloudSyncOutlined
      key="syncGroup"
      title="sync groups"
      hidden={true}
      onClick={() => {
        modalState(true, "group");
      }}
    />,
  ];

  return (
    <div>
      <Spin spinning={loading}>
        <p>Data Sync</p>
        <div style={{ padding: "5px" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
              gap: "20px",
            }}
          >
            <Card
              actions={pendingUsers?.length > 0 ? userActions : null}
              style={{ minWidth: 300 }}
            >
              <Card.Meta
                avatar={<UserOutlined />}
                title="Users"
                description={
                  <div>
                    <p className="fs-10">
                      Total number of users in server -{" "}
                      {userData?.adUsersCount || 0}
                    </p>
                    <p className="fs-10">
                      Total number of users in Application -{" "}
                      {userData?.applicationUsersCount || 0}
                    </p>
                    <p className="fs-10">
                      Pending Users - {userData?.usersToAdd?.length || 0}
                    </p>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Progress
                        percent={userProgressPercent}
                        status={
                          userProgressPercent <= 0 ? "exception" : "active"
                        }
                      />
                    </div>
                  </div>
                }
              />
            </Card>
            <Card
              actions={pendingGroups?.length > 0 ? groupActions : null}
              style={{ minWidth: 300 }}
            >
              <Card.Meta
                avatar={<UsergroupAddOutlined />}
                title="Groups"
                description={
                  <div>
                    <p className="fs-10">
                      Total number of groups in server -{" "}
                      {groupData?.adGroupsCount || 0}
                    </p>
                    <p className="fs-10">
                      Total number of groups in Application -{" "}
                      {groupData?.applicationGroupsCount || 0}
                    </p>
                    <p className="fs-10">
                      Pending Groups - {groupData?.groupsToAdd?.length || 0}
                    </p>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Progress
                        percent={groupProgressStatus}
                        status={
                          groupProgressStatus <= 0 ? "exception" : "active"
                        }
                      />
                      {/* <SyncOutlined spin style={{color:'#2b84ff'}} /> */}
                    </div>
                  </div>
                }
              />
            </Card>
          </div>
          <br />
          <div>
            <GroupUsers />
          </div>
        </div>

        <div>
          {/* pending user/group List drawer */}
          <Drawer
            title={
              drawerStatus?.title === "user"
                ? "Pending Users"
                : "Pending Groups"
            }
            placement={"right"}
            width={500}
            style={{ header: { padding: "10px !important" } }}
            closable={false}
            onClose={(e) => {}}
            open={drawerStatus?.isOpen}
            extra={
              <Space>
                <Button
                  onClick={() => {
                    drawerState(false, "");
                  }}
                >
                  Close
                </Button>
              </Space>
            }
          >
            <div>
              <Table
                bordered
                dataSource={
                  drawerStatus?.title === "user" ? pendingUsers : pendingGroups
                }
                columns={
                  drawerStatus?.title === "user" ? userColumn : groupColumn
                }
                rowKey="id"
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ["5", "10", "20", "50"],
                  onChange: (page, pageSize) => {
                    setPagination({ current: page, pageSize });
                  },
                  onShowSizeChange: (current, size) => {
                    setPagination({ current: 1, pageSize: size }); // Reset to first page on page size change
                  },
                }}
                onChange={handleTableChange} // Handle pagination changes
                scroll={{ y: 300 }}
              />
            </div>
          </Drawer>
          {/* sync user / group data confirm modal */}
          <Modal
            open={modalStatus?.isOpen}
            title={modalStatus?.title}
            onOk={(e) => {
              modalState(false, "");
              syncData(modalStatus?.title);
            }}
            onCancel={(e) => {
              modalState(false, "");
            }}
            footer={(_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            )}
          >
            <p>Are you sure, you want to sync the {modalStatus?.title} data!</p>
          </Modal>
        </div>
      </Spin>
    </div>
  );
};

export default DataSync;
